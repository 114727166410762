import App from './App.svelte';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.css';
import { faGithub, faDiscord, faFacebook, faTwitter, } from '@fortawesome/free-brands-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { faPenFancy } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
const app = new App({
    target: document.body,
    props: {
        name: 'Hayden Lewis',
        username: 'Hayden Lewis',
        avatarUrl: 'https://avatars.githubusercontent.com/u/68645946?v=4',
        url: [
            {
                name: 'newsletter',
                url: 'https://facebook.com/falcxx',
            },
            {
                name: 'blog',
                url: 'https://github.com/gifaldyazkaa',
            },
            {
                name: 'about',
                url: 'https://facebook.com/falcxx',
            },
            {
                name: 'twitter',
                url: 'https://twitter.com/gifaldyazkaa',
            },
        ],
    },
});
export default app;
